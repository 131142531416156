<template>
	<div class="app " :class="`${openedleft ? 'opened-left-panel' : ''}${openedcontests ? ' opened-contests-panel' : ''}`">
		<Head :flagShow="openedleft" :isMobile="isMobileFlag" @menuClick="menuClick" />
		<div class="wrapper">
			<div class="wrapper__inner">
				<div class="container">
					<article class="fairness">
						<section class="home">
							<h1 class="home__title">{{ $t('如何检验公平性？') }}</h1>
							<p class="home__sub-title">{{ $t('fairness_desc1') }}</p>
							<div class="home__img-wrap">
								<picture>
									<source media="(max-width:768px)" srcset="../../assets/images/racoon-fairness-m.png"/>
									<img loading="lazy" src="../../assets/images/racoon-fairness.png" alt="money" class="home__img">
								</picture>
							</div>
						</section>
						<section class="how">
							<header class="how__header">
								<h2 class="how__header-title">{{ $t('它是如何工作的？') }}</h2>
								<p class="how__header-sub-title">{{ $t('fairness_desc2') }}</p>
							</header>
							<article class="box">
								<div>
									<h4 class="box__title">
										<img loading="lazy" src="../../assets/images/fairness01.svg" alt="index" class="box__index-img">
										<span>{{ $t('合作公正') }}</span>
									</h4>
									<p class="box__sub-title">{{ $t('fairness_desc3') }}</p>
								</div>
								<img loading="lazy" :src="$t('0-fairness-desc')" alt="desc" class="box__desc-img">
							</article>
							<article class="box">
								<div>
									<h4 class="box__title">
										<img loading="lazy" src="../../assets/images/fairness02.svg" alt="index" class="box__index-img">
										<span>{{ $t('投注') }}</span>
									</h4>
									<p class="box__sub-title">{{ $t('fairness_desc4') }}</p>
								</div>
								<img loading="lazy" :src="$t('1-fairness-desc')" alt="desc" class="box__desc-img">
							</article>
							<article class="box">
								<div>
									<h4 class="box__title">
										<img loading="lazy" src="../../assets/images/fairness03.svg" alt="index" class="box__index-img">
										<span>{{ $t('检查验证') }}</span>
									</h4>
									<p class="box__sub-title">{{ $t('fairness_desc5') }}</p>
								</div>
								<img loading="lazy" :src="$t('2-fairness-desc')" alt="desc" class="box__desc-img">
							</article>
							<router-link to="/all-game" class="button button_lg btn-yellow button_center">
								<span class="button__inner">
									<span class="button__text">{{ $t('开始游戏') }}</span>
								</span>
							</router-link>
						</section>
						<section class="faq">
							<h2 class="faq__title">{{ $t('常见问题') }}</h2>
							<div class="faq-items">
								<div class="faq-items__column">
									<div class="faq-element" @click="faqClick(item)" v-for="(item,index) in faqListleft" :key="index" :class="item.select?'faq-show':''">
										<div class="faq-element__wrapper">
											<h3 class="faq-element__title">
												{{ $t(item.title) }}
											</h3>
											<div class="faq-element__icon-block">
												<svgIcon icon="icon-arrow-down" widthName="20" heightName="20" className="faq-element__icon-arrow"></svgIcon>
											</div>
										</div>
										<p class="faq-element__description" v-html="$t('fairness_faq_' + item.id)"></p>
									</div>
								</div>
								<div class="faq-items__column">
									<div class="faq-element" @click="faqClick(item)" v-for="(item,index) in faqListright" :key="index" :class="item.select?'faq-show':''">
										<div class="faq-element__wrapper">
											<h3 class="faq-element__title">
												{{ $t(item.title) }}
											</h3>
											<div class="faq-element__icon-block">
												<svgIcon icon="icon-arrow-down" widthName="20" heightName="20" className="faq-element__icon-arrow"></svgIcon>
											</div>
										</div>
										<p class="faq-element__description" v-html="$t('fairness_faq_' + item.id)"></p>
									</div>
								</div>
							</div>
						</section>
					</article>
				</div>
			</div>
			<Foot />
		</div>
		<asidebar :flagShow="openedleft" @menuClick="menuClick" ></asidebar>
		<tabbar :flagShow="openedleft" @menuClick="menuClick" @contestsShow="contestsShow"></tabbar>
		<div class="app-overlay" v-if="isMobileFlag" :class="openedleft?'visible':''" @click="menuClick"></div>
	</div>
</template>

<script>
	// @ is an alias to /src
		import Head from '@/components/Head.vue'
		import Foot from '@/components/Foot.vue'
		import asidebar from '@/components/aside.vue'
		import svgIcon from '@/components/svg.vue'
		import tabbar from '@/components/tabbar.vue'
	export default {

		components: {
				Head,
				Foot,
				asidebar,
				svgIcon,
				tabbar
			},
		computed: {
			faqListleft: function () {
				var list = this.faqList;
				var arrTemp = [];
				for (var i = 0; i < list.length; i++) {
					if (i % 2 == 0) {
						arrTemp.push(list[i]);
					}
				}
				return arrTemp;
			},
			faqListright: function () {
				var list = this.faqList;
				var arrTemp = [];
				for (var i = 0; i < list.length; i++) {
					if (i % 2 != 0) {
						arrTemp.push(list[i]);
					}
				}
				return arrTemp;
			}
		},
		data() {
			return {
				windowWidth: document.documentElement.clientWidth,
				openedleft:true,
				openedcontests:false,
				isMobileFlag:false,// 响应式
				faqCur:0,
				faqList:[
					{
						id:0,
						title:'1.什么是I-Gaming游戏？',
						content:'',
						select: false
					},{id:1,
						title:'2.I-Gaming游戏有哪些优势？',
						content:'',
						select: false
					},{id:2,
						title:'3.I-Gaming游戏的可用性？',
						content:'',
						select: false
					},{id:3,
						title:'4.I-Gaming游戏丰富！',
						content:'',
						select: false
					},{id:4,
						title:'5.为什么与比特币相同的SHA256技术是公平的？',
						content:'',
						select: false
					},{id:5,
						title:'6.为什么是SHA-256？',
						content:'',
						select: false
					}
				],
			};
		},
		methods: {
			faqClick(item) {
				for (let i = 0; i < this.faqList.length; i++) {
					this.faqList[i].select = !item.select && item.id == this.faqList[i].id
				}
			},

			menuClick(){
				this.openedleft = !this.openedleft
			},
			contestsClick(){
				this.openedcontests = !this.openedcontests
			},
			contestsShow(){
				this.openedcontests = true
			},
		},
		created() {
			this.isPhone()
		},
	}
</script>
<style scoped lang="less">

	.home {
		display: grid;
		grid-template-rows: repeat(2, 142px);
		grid-template-columns: minmax(489px, 1fr) minmax(642px, 642px);
		grid-template-areas: "title img-wrap""sub-title img-wrap"
	}

	.home__title {
		font-weight: 500;
		font-size: 34px;
		line-height: 60px;
		text-transform: uppercase;
		color: #C2F6E6;
		align-self: end;
		margin-bottom: 20px;
		grid-area: title
	}

	.home__sub-title {
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;
		color: #AED9CC;
		margin: 0;
		grid-area: sub-title
	}

	.home__img-wrap {
		width: 100%;
		position: relative;
		grid-area: img-wrap;
		z-index: 2;
	}



	.how {
		display: flex;
		flex-direction: column;
		background: #07503F;
		border-radius: 6px;
		padding: 44px 33px;
		margin-bottom: 45px;
	}

	.how__header {
		text-align: left;
		max-width: 582px;
		border-bottom: 1px solid #095C49;
		padding-bottom: 31px;
		margin-bottom: 30px;
		@media (max-width:768px) {
			border: none;
			padding-bottom: 0;
		}
	}

	.how__header-title {
		font-weight: 500;
		font-size: 28px;
		color: #C2F6E6;
	}

	.how__header-sub-title {
		font-size: 18px;
		font-weight: normal;
		color: #67AA96;
	}

	.box {
		display: flex;
		align-items: center;
		margin-bottom: 85px;
		gap: 44px;
		&:nth-child(3){
			flex-direction: row-reverse;
		}
	}

	.box__index-img {
		width: 100px;
	}

	.box__title {
		font-weight: 500;
		font-size: 26px;
		line-height: 1.1;
		display: flex;
		align-items: flex-end;
		gap: 10px;
		color: #D7FFC4;
		@media (max-width:768px) {
			width: 100%;
			text-align: center;
			display: block;
			position: relative;
		}
		span{
			@media (max-width:768px) {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
				z-index: 2;
				white-space: nowrap;
				font-size: 14px;
				background-color: #07503F;
			}
		}
	}

	.box__sub-title {
		font-weight: 400;
		font-size: 18px;
		color: #AED9CC;
	}
  @media(max-width:768px){
    .box__sub-title{
      margin-bottom: 15px;
    }
  }
	.box__desc-img {
		width: 423px;
	}

	.faq__title {
		font-weight: 500;
		font-size: 26px;
		line-height: 29px;
		text-align: left;
		color: #fff;
		margin-bottom: 45px;
		@media (max-width:768px) {
			font-size: 15px;
			margin-bottom: 13px;
		}
	}

	@media(max-width:768px) {
		.box__desc-img,.box__sub-title,.how__header-sub-title{
			font-size: 14px;
			text-align: center;
		}
		.fairness {
			padding-top: 30px
		}

		.home {
			text-align: center;
			grid-template-rows: auto;
			grid-template-columns: auto;
			grid-template-areas: "title""sub-title""img-wrap"
		}

		.home__title {
			font-size: 18px;
			line-height: 1.3;
			margin-bottom: 10px;
		}

		.home__sub-title {
			margin: 0 auto;
			color: #67AA96;
			font-size: 14px;
			line-height: 1.3;
			max-width: 300px;
		}

		.home__img-wrap {
			margin: 0;
			margin-left: auto;
			margin-right: -16px;
			max-width: 360px;
			width: 360px;
			margin-top: 100px;
			margin-bottom: 50px;
		}

		.home__img-wrap:after {
			z-index: 1
		}

		.home__img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2
		}

		.how {
			padding-left: 25px;
			padding-right: 25px;
		}

		.how__header-title {
			font-size: 18px;
			text-align: center;
			margin: 10px 0;
		}

		.box {
			flex-direction: column !important;
			gap: 10px;
			margin-bottom: 20px;
		}
		.box__desc-img {
			width: 375px;
			max-width: 100%;
		}
	}

	.faq-items{
		margin-bottom: 50px;
		display: flex;
		width: 100%
	}

	@media(max-width:1100px) {
		.faq-items{
			flex-direction: column
		}
	}

	@media(max-width:1332px) {
		.opened-left-panel .faq-items{
			flex-direction: column
		}
	}

	@media(max-width:1425px) {
		.opened-right-panel .faq-items{
			flex-direction: column
		}
	}

	@media(max-width:1440px) {
		.opened-contests-panel .faq-items{
			flex-direction: column
		}
	}

	@media(max-width:1657px) {
		.opened-left-panel.opened-right-panel .faq-items{
			flex-direction: column
		}
	}

	@media(max-width:1672px) {
		.opened-left-panel.opened-contests-panel .faq-items{
			flex-direction: column
		}
	}

	.faq-items__column{
		width: 50%;
		margin-right: 24px
	}

	.faq-items__column:last-child {
		margin-right: 0
	}

	@media(max-width:1100px) {
		.faq-items__column{
			width: 100%;
			margin-right: 0
		}
	}

	@media(max-width:1332px) {
		.opened-left-panel .faq-items__column{
			width: 100%;
			margin-right: 0
		}
	}

	@media(max-width:1425px) {
		.opened-right-panel .faq-items__column{
			width: 100%;
			margin-right: 0
		}
	}

	@media(max-width:1440px) {
		.opened-contests-panel .faq-items__column{
			width: 100%;
			margin-right: 0
		}
	}

	@media(max-width:1657px) {
		.opened-left-panel.opened-right-panel .faq-items__column{
			width: 100%;
			margin-right: 0
		}
	}

	@media(max-width:1672px) {
		.opened-left-panel.opened-contests-panel .faq-items__column{
			width: 100%;
			margin-right: 0
		}
	}

	.faq-element{
		padding: 24px 0;
		border-top: 1px solid #07503F;
		cursor: pointer
	}

	.faq-element:last-child {
		border-bottom: 1px solid #07503F
	}

	.faq-element__wrapper{
		position: relative
	}

	.faq-element__title{
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		padding-right: 30px;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 0
	}

	.faq-show .faq-element__title,
	.faq-show .faq-element__title:hover {
		color: #D7FFC4
	}

	.faq-element__title:hover {
		color: #AED9CC
	}

	.faq-element__description{
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #86D39D;
		opacity: 0;
		height: 0;
		margin: 0;
		transition: .3s all;
	}

	.faq-show .faq-element__description{
		opacity: 1;
		margin: 17px 0 0;
		height: auto
	}

	.faq-element__icon-block{
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(-50%, -50%)
	}

	.faq-element__icon-arrow{
		fill: #AED9CC;
		transition: all .2s
	}

	.faq-show .faq-element__icon-arrow{
		transform: rotate(180deg)
	}

	.faq-show{
		max-height: none
	}
	.btn-yellow{
		width: fit-content;
		margin: 0 auto;
		min-width: 270px;
	}
</style>
